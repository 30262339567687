<template>
  <Header></Header>
  <router-view/>
  <p class="link" v-if="showLink"><a href="https://1251349076.vod2.myqcloud.com/45e704edvodtransgzp1251349076/72f9a9dd1397757899954102828/v.f40673.mp4" target="_blank">图形化编程作品平台入门教程</a></p>
</template>
<script lang="ts">
import { ref, defineComponent, onBeforeMount, watch } from 'vue';
import Header from '@/components/Header.vue'
import store from './store';
import { useRoute } from 'vue-router';
export default defineComponent({
  name: 'HomeView',
  components: {
    Header
  },
  setup() {
    const route = useRoute()
    const showLink = ref(false)
    onBeforeMount(() => {
      const isLogin = sessionStorage.getItem('isLogin')
      if (!isLogin) {
        store.commit('updateInfo', {
          store_id: null,
          name: '',
          short_name: ''
        })
      }
    })
    watch(() => route.fullPath, (val) => {
      showLink.value = val.includes('blocks-experience')
    })
    return {
      showLink
    }
  },
});
</script>

<style lang="scss">
@import url('./App.scss');
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  padding-top: 96px;
}

.link {
  width: 100%;
  position: fixed;
  bottom: 0;
  font-size: 14px;
  text-align: center;
  background: #fff;
  margin-bottom: 0;
  padding: 10px 0;

  a {
    color: var(--font-color-link);
  }

}
</style>
